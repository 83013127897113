/** @format */

@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600;700;800&display=swap");

// Theme Start

// Colors
$textColor: #fff;

// Font Family
$Prompt: "Prompt", sans-serif;

::-webkit-scrollbar-thumb {
  background: linear-gradient(160deg, #21a056, #3ec878);
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar {
  width: 5px;
}
::selection {
  background: #3ec878;
  color: $textColor;
}
a {
  text-decoration: none !important;
}

// Theme End

*body {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  // user-select: none;
  // -webkit-user-select: none;
  // -moz-user-select: none;
}

.navbar {
  .navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;

    .Layer_1 {
      width: 15%;
    }
    span {
      color: #fff;
      margin: 0 15px;
      font-family: $Prompt;
      font-size: 16px;
      letter-spacing: 0.8px;
      font-weight: 500;
    }
  }
  .nav-link {
    font-size: 13px;
    padding: 16px 20px !important;
    color: #eaeaea;
    font-weight: 400;
    font-family: $Prompt;
    cursor: pointer;
    letter-spacing: 1.2px;
    &:hover {
      // color: #b4903a !important;
    }
  }
  .active {
    color: #fff !important;
  }
}
.navbar-nav {
  // margin-left: 12px;
}
.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
}

.start_btn {
  padding: 10px 0;
  a {
    text-decoration: none;
    background-color: #fff;
    color: #464646 !important;
    font-size: 12.5px;
    padding: 5px 30px;
    font-family: $Prompt;
    font-weight: 300;
    text-transform: uppercase;
    border-radius: 40px;
    letter-spacing: 0.8px;
  }
}
.search_bar {
  padding: 10px 0 !important;
  margin: 0 10px;
  input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    text-transform: uppercase;
    font-size: 10px;
    font-family: $Prompt;
    border-radius: 40px;
    border: 1px solid #fff;
    background: transparent;
    letter-spacing: 0.8px;
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  border: none !important;
}

//   Navbar End

//   Hero Section Start
.hero_sec {
  background-image: url("media/images/hero_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 150px 85px 154px;

  position: relative;

  .right_img {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-right: 30px;
    width: 32%;
    opacity: 0.6;
  }

  h3 {
    font-size: 95px;
    color: $textColor;
    font-family: $Prompt;
    font-weight: 400;
    line-height: 5.5rem;
    padding: 54px 0 0;
  }
  p {
    color: $textColor;
    font-size: 24px;
    font-family: $Prompt;
    margin-top: 30px;
  }
  .btn_main {
    .btn_1 {
      padding: 4px 35px;
      background: linear-gradient(160deg, #21a056, #3ec878);
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: $textColor;
      font-weight: 300;
      margin: 0 4px;
      transition: opacity 0.3s;
      &:hover {
        background: linear-gradient(160deg, #3ec878, #21a056);
      }
    }
    .btn_2 {
      padding: 4px 35px;
      background-color: $textColor;
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: #464646;
      font-weight: 300;
      margin: 0 4px;
      box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);
    }
  }
  .small_txt {
    font-size: 10px;
    color: $textColor;
    font-weight: 600;
    margin-top: 80px;
    text-transform: uppercase;
  }
  .repeat_txt {
    width: 22%;
    height: 40vh;
    -webkit-text-stroke: 1px white;
    color: transparent;
    font-size: 95px;
    font-family: $Prompt;
    font-weight: 400;
    line-height: 5.5rem;
  }
  .eyebrow {
    position: relative;
    display: inline-block;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font-size: 22px;
    color: black;
    padding: 6px 50px 6px 0;
    background: rgba(255, 255, 255, 0.5) url("media/images/logo1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px);
    font-family: $Prompt;
    letter-spacing: 1px;
    margin-left: -34px;
    &::before {
      content: "";
      background-color: rgba(255, 255, 255, 0.5);
      display: block;
      position: absolute;
      height: 100%;
      width: 6.25vw;
      left: -6.25vw;
      top: 0;
    }
  }
}

// Numbers Section
.section-tabs {
  margin: auto;
  display: flex;
  border-bottom: none !important;
  .nav-item {
    // margin: 5px !important;
    .nav-link {
      padding: 4px 25px;
      background-color: transparent !important;
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 15px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: #464646;
      font-weight: 600;
      margin: 0 4px;
      box-shadow: 0px !important;
      &:hover {
        color: #3ec878;
      }
    }
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    color: #3ec878 !important;
  }
}
.nav-tabs.hedera_tabs {
  margin: auto;
  display: flex;
  border-bottom: none !important;
  .nav-item {
    margin: 5px !important;
    .nav-link {
      padding: 4px 35px;
      background-color: $textColor !important;
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: #464646;
      font-weight: 300;
      margin: 0 4px;
      box-shadow: 0px 0px 6px 0px rgba(177, 175, 175, 0.5) !important;
      &:hover {
        background: linear-gradient(160deg, #3ec878, #21a056);
        color: $textColor;
      }
    }
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    background: linear-gradient(160deg, #21a056, #3ec878);
    color: $textColor !important;
  }
}
.numbers_sec {
  position: relative;
  margin-top: -120px;
  margin-bottom: 60px;
  .main_card {
    text-align: center;
    padding: 90px 5vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    border-top-right-radius: 160px;
    border-bottom-left-radius: 160px;
    background-color: white;

    h4 {
      color: #3ec878;
      font-size: 28px;
      font-family: $Prompt;
      font-weight: 400;
    }

    .para {
      p {
        font-size: 14px;
        color: #222222;
        font-weight: 500;
        font-family: $Prompt;
        line-height: 20px;
      }
    }
  }
}

// Number Section End

//   Hero Section End

// Section Start
.section_bg {
  padding: 30px 0;
  cursor: pointer;

  .sec_left {
    h3 {
      font-size: 48px;
      color: #222222;
      font-family: $Prompt;
      font-weight: 700;
      margin-bottom: 10rem;
      span {
        font-weight: 300;
      }
    }
  }
  .sec_mid {
    max-height: 800px; /* Set the maximum height for the list */
    border-left: 3px solid #222222;
  }
  .sec_right {
    max-height: 750px; /* Set the maximum height for the list */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */

    h5 {
      font-size: 28px;
      color: #222222;
      font-family: $Prompt;
      font-weight: 300;
      span {
        font-weight: 700;
        font-size: 34px;
      }
    }
    p {
      color: #222222;
      font-size: 15px;
      font-family: $Prompt;
      margin: 40px 0;
    }
    .btn_1 {
      padding: 4px 35px;
      background: linear-gradient(160deg, #21a056, #3ec878);
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: $textColor;
      font-weight: 300;
      margin: 0 4px;
      transition: opacity 0.3s;
      &:hover {
        background: linear-gradient(160deg, #3ec878, #21a056);
      }
    }
    .images {
      margin: 10px;
      img {
        width: 28%;
        display: block;
        mix-blend-mode: luminosity;
        &:hover {
          mix-blend-mode: unset;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #07b60c;
    border-radius: 8px;
    // outline: 1px solid slategrey;
  }
}
// Section End
@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}
:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(168, 239, 255, 1);
  --c2: rgba(168, 239, 255, 0.1);
}
iframe {
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #07b60c !important;
    border-radius: 8px !important;
  }
}

// Hedera Section Start
.hedera_sec {
  .container {
    background-image: url("media/images/h2.png");
    background-position: center;
    background-size: cover;
    border-radius: 0 100px 0 100px;
    padding: 80px 0;
    height: auto !important;
    .img2 {
      min-height: 100vh;
      position: relative;
    }
    .img3 {
      // top: 22%;
      // right: 9%;
      // height: 65%;
      // position: absolute;
      border: 0.35rem solid;
      border-image: conic-gradient(
          from var(--angle),
          var(--c2),
          var(--c1) 0.1turn,
          var(--c1) 0.15turn,
          var(--c2) 0.25turn
        )
        30;
      animation: borderRotate var(--d) linear infinite forwards;

      // border-radius: 50%;
      width: 200px !important;
      height: 230px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #00000027;
      box-shadow: inset 0 0 10px #969997;
      backdrop-filter: blur(4px);
    }
    .text {
      // width: 50%;
      color: $textColor;
      position: absolute;
      top: 0%;
      left: 1.3%;

      h3 {
        font-size: 36px;
        font-family: $Prompt;
        font-weight: 400;
        margin-bottom: 30px;
        span {
          font-weight: 600;
        }
      }
      p {
        font-size: 18px;
        font-family: $Prompt;
      }
    }
    .btn_main {
      .btn_1 {
        padding: 4px 35px;
        background: linear-gradient(160deg, #21a056, #3ec878);
        border: none;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.8px;
        font-family: $Prompt;
        color: $textColor;
        font-weight: 300;
        margin: 0 4px;
        transition: opacity 0.3s;
        &:hover {
          background: linear-gradient(160deg, #3ec878, #21a056);
        }
      }
      .btn_2 {
        padding: 4px 35px;
        background-color: $textColor;
        border: none;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.8px;
        font-family: $Prompt;
        color: #464646;
        font-weight: 300;
        margin: 0 4px;
        box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);
      }
    }
  }
}
@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

// Hedera Section End

// Cards Section Start
.cards_sec {
  background-image: url("media/images/c1.svg");
  background-position: center;
  background-size: cover;
  padding: 80px 0;
  p {
    font-size: 15px;
    color: #222222;
    font-family: $Prompt;
    // margin-bottom: 50px;
  }
  .card {
    padding: 40px 30px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);
    border-radius: 10px;
    border-color: #cccccc;
    border-width: 1px;
    border-style: solid;
    height: 100%;
    img {
      width: 20%;
    }
    h4 {
      font-family: $Prompt;
      font-size: 16px;
      margin: 20px 0;
      color: #222222;
    }
    p {
      font-size: 15px;
      color: #222222;
      font-family: $Prompt;
      // margin-bottom: 50px;
    }
  }
  h3 {
    font-family: $Prompt;
    font-size: 36px;
    color: #222222;
    font-weight: 400;
  }
  .btn_main {
    position: absolute;
    bottom: 10%;
    button {
      padding: 4px 35px;
      background-color: $textColor;
      border: 1px solid #3ec878;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: #464646;
      font-weight: 300;
      margin: 0 4px;
      box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);
      &:hover {
        color: #fff;
        background: linear-gradient(160deg, #5281e7, #3ec878);
      }
    }
  }
  .btn_1 {
    padding: 4px 35px;
    background: linear-gradient(160deg, #21a056, #3ec878);
    border: none;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.8px;
    font-family: $Prompt;
    color: $textColor;
    font-weight: 300;
    margin: 0 4px;
    transition: opacity 0.3s;
    &:hover {
      background: linear-gradient(160deg, #3ec878, #21a056);
    }
  }
}
// Cards Section End

// Services Section Start
.services_sec {
  padding: 70px 0;
  h2 {
    font-family: $Prompt;
    text-align: center;
    font-size: 36px;
    color: #222222;
    font-weight: 400;
  }
  .card_main {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 40px 0;
  }

  img {
    width: 20%;
  }
  p {
    font-family: $Prompt;
    font-size: 18px;
  }
}
// Services Section End

// Future Section Start
.future_sec {
  .home-hello-future {
    // padding: 0 6.25% 6.25%;
    position: relative;
  }
  .clip-text {
    background-image: url(https://images.hedera.com/hellofuture-gradient.jpg?w=1680&h=1038&auto=compress%2Cformat&fit=crop&dm=1688765216&s=d0c48f0…);
    background-position: -46.1563vw 50%;
    background-attachment: fixed;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    /* background-size: cover; */
    background-size: 100vw;
    background-position: -100vw center;
    background-repeat: no-repeat;
    background-color: #222222;
    font-size: 19.35vw;
    margin-left: -1.5vw;
    margin-bottom: -3vw;
    letter-spacing: -1.25vw;
    font-weight: 400;
  }
  h4 {
    font-family: $Prompt;
    font-size: 38px;
    font-weight: 400;
    color: #222222;
  }
  p {
    font-family: $Prompt;
    font-size: 15px;
    font-weight: 400;
    color: #222222;
  }
  .cards_sec {
    h5 {
      font-size: 16px;
      color: #000;
      font-family: $Prompt;
      letter-spacing: 1px;
    }
    .btn_main2 {
      button {
        padding: 4px 40px;
        background: linear-gradient(160deg, #21a056, #3ec878);
        border: none;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.8px;
        font-family: $Prompt;
        color: $textColor;
        font-weight: 300;
        margin: 0 4px;
        transition: opacity 0.3s;
        &:hover {
          background: linear-gradient(160deg, #3ec878, #21a056);
        }
      }
    }
    .card {
      background-image: radial-gradient(
          circle at 100% 100%,
          transparent 8px,
          #2d84eb 8px,
          #2d84eb 10px,
          transparent 10px
        ),
        linear-gradient(to right, #2d84eb, #8259ef),
        radial-gradient(
          circle at 0% 100%,
          transparent 8px,
          #8259ef 8px,
          #8259ef 10px,
          transparent 10px
        ),
        linear-gradient(to bottom, #8259ef, #2e86eb),
        radial-gradient(
          circle at 0% 0%,
          transparent 8px,
          #2e86eb 8px,
          #2e86eb 10px,
          transparent 10px
        ),
        linear-gradient(to left, #2e86eb, #2d85eb),
        radial-gradient(
          circle at 100% 0%,
          transparent 8px,
          #2d85eb 8px,
          #2d85eb 10px,
          transparent 10px
        ),
        linear-gradient(to top, #2d85eb, #2d84eb);
      background-size: 10px 10px, calc(100% - 20px) 2px, 10px 10px,
        2px calc(100% - 20px);
      background-position: top left, top center, top right, center right,
        bottom right, bottom center, bottom left, center left;
      background-repeat: no-repeat;
      background-color: #ffffffb3;
      border-radius: 10px;
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 236px;
      box-sizing: border-box;
      border: none;
      // margin: 0 30px;
      span {
        border: 1px solid #222222;
        font-size: 12px;
        text-transform: uppercase;
        padding: 0 10px;
        margin: 2px 4px;
        border-radius: 40px;
        cursor: pointer;
        font-family: $Prompt;
        &:hover {
          background: linear-gradient(160deg, #8194ff, #f78bf7);
          color: $textColor;
        }
      }
      img {
        width: 70%;
      }
      h5 {
        font-family: $Prompt;
        color: #000;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
      }
      p {
        text-align: center;
        margin-bottom: 8px;
      }
      .icons {
        cursor: pointer;
        margin: 0 6px;
      }
    }
    .carousel {
      width: 47.5rem;
    }
    .btn2_main {
      button {
        padding: 4px 40px;
        background-color: transparent;
        border: none;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.8px;
        font-family: $Prompt;
        color: gray;
        font-weight: 300;
        margin: 0 0 8px 0;
        box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);
        background: linear-gradient(134.82deg, #565656 0%, #222222 100%);
        color: $textColor;
        &:hover {
          background: linear-gradient(134.82deg, #222222 100%, #565656 0%);
        }
      }
    }
  }
}
.react-multiple-carousel__arrow {
  background: linear-gradient(160deg, #21a056, #7e91ff) !important;
  padding: 20px 30px;
  border-radius: 100px !important;
}
// Future Section End

// Started Section
.started_sec {
  padding: 80px 0;
  h4 {
    font-family: $Prompt;
    font-size: 38px;
    font-weight: 400;
    color: #222222;
  }
  p {
    font-family: $Prompt;
    font-size: 15px;
    font-weight: 400;
    color: #222222;
  }
  .card {
    padding: 40px 50px;
    background-position: center;
    background-size: cover;
    border-radius: 30px;

    h5 {
      font-size: 17px;
      font-family: $Prompt;
      color: $textColor;
      letter-spacing: 1px;
      margin-bottom: 25px;
    }
    p {
      color: #fff;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 40px;
    }
    .btn_main {
      button {
        padding: 6px 30px;
        background-color: $textColor;
        border: none;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.8px;
        font-family: $Prompt;
        color: #464646;
        font-weight: 300;
        margin: 0 4px;
        box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);
      }
    }
  }
}
// Started Section End

// Build Section
.build_sec {
  background-image: url("media/images/build.png");
  background-position: center;
  background-size: cover;
  padding: 80px 0 120px 0;

  h3 {
    font-size: 38px;
    color: #fff;
    font-family: $Prompt;
    margin-bottom: 25px;
  }
  p {
    color: #fff;
    font-size: 15px;
    font-family: $Prompt;
    margin-bottom: 40px;
  }
  .btn_main {
    .btn_1 {
      padding: 4px 35px;
      background: linear-gradient(160deg, #21a056, #3ec878);
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: $textColor;
      font-weight: 300;
      margin: 0 4px;
      transition: opacity 0.3s;
      &:hover {
        background: linear-gradient(160deg, #3ec878, #21a056);
      }
    }
    .btn_2 {
      padding: 4px 35px;
      background-color: $textColor;
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: #464646;
      font-weight: 300;
      margin: 0 4px;
      box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);
    }
  }
  h5 {
    font-size: 15px;
    color: #fff;
    font-weight: 700;
    margin: 15px 0;
  }
  .right {
    border-left: 2px solid #fff;
  }
}

// commitment Section

.commitment_sec {
  position: relative;
  margin-top: -120px;
  margin-bottom: 60px;
  .main_card {
    padding: 90px 5vw;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    border-top-right-radius: 160px;
    border-bottom-left-radius: 160px;
    background-color: white;
    border: 6px solid #5281e7;

    h4 {
      color: #222222;
      font-size: 38px;
      font-family: $Prompt;
      font-weight: 300;
    }

    p {
      font-size: 16px;
      color: #222222;
      font-weight: 400;
      font-family: $Prompt;
      line-height: 20px;
      margin-bottom: 40px;
    }
    .btn_main {
      display: flex;
      justify-content: flex-end;
      margin-top: 60px;
      .btn_1 {
        padding: 4px 35px;
        background: linear-gradient(160deg, #21a056, #3ec878);
        border: none;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.8px;
        font-family: $Prompt;
        color: $textColor;
        font-weight: 300;
        margin: 0 4px;
        transition: opacity 0.3s;
        &:hover {
          background: linear-gradient(160deg, #3ec878, #21a056);
        }
      }
    }
  }
  .sustainability-graph-image {
    margin-top: -21rem;
  }
}

// commitment Section End

// Build Section End

// Contact Section Start
.contact_sec {
  padding: 80px 0;
  background-color: #000;

  h3 {
    color: $textColor;
    text-transform: uppercase;
    font-size: 13rem;
    font-weight: 600;
    font-family: $Prompt;
  }

  .card {
    background-color: transparent;
    border: 1px solid rgb(63, 63, 63);
    padding: 25px 30px;
    height: 22rem;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: #1f1e1e;
    }

    a {
      color: #fff;
      font-weight: 500;
      opacity: 0.4;
      font-size: 18px;
      margin-bottom: clamp(6px, 0.5859375vw, 11.25px);
      display: block;
      &:hover {
        color: $textColor;
      }
    }

    h4 {
      // text-transform: uppercase;
      font-size: 26px;
      letter-spacing: 0.8px;
      color: $textColor;
      font-family: $Prompt;
    }
  }
}
// Contact Section End

// Work Section Start
.work_sec {
  padding: 60px 0;
  h3 {
    font-size: 36px;
    font-family: $Prompt;
    font-weight: 400;
    margin-bottom: 30px;
    span {
      font-weight: 600;
    }
  }
  p {
    font-size: 18px;
    font-family: $Prompt;
  }
  .card {
    background-color: transparent;
    border: 1px solid rgb(63, 63, 63);
    padding: 15px 20px;
    height: 20rem;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card_img {
      img {
        width: 24%;
      }
    }

    h4 {
      // text-transform: uppercase;
      font-size: 26px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
    }
  }
}
// Work Section End

// Github Section Start
.github_sec {
  padding: 70px 0;

  .card_main {
    background-color: transparent;
    border: 1px solid rgb(185, 185, 185);
    padding: 15px 20px;
    border-radius: 20px;

    h4 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 700;
    }

    .btn_main {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: 100%;
        color: #000;
        font-size: 38px;
        font-weight: 700;
        padding: 10px 0;
        border-radius: 15px;
      }
    }
    p {
      margin: 10px 0;
      color: #000;
      font-weight: 600;
      font-size: 18px;
      span {
        color: #2d85eb;
      }
    }
    a {
      color: #000;
      font-weight: 600;
    }
  }
}
// Github Section End

// Footer Section Start
.footer {
  padding: 50px 0;
  img {
    mix-blend-mode: luminosity !important;
    // filter: invert(0);
  }
  .input_main {
    width: 40%;
    font-size: 30px;
    font-family: $Prompt;
    &:hover {
      -webkit-transition: border-bottom 0.3s ease-out !important;
      transition: border-bottom 0.3s ease-out !important;
      border-bottom: 1px solid #fff;
    }

    input {
      color: $textColor;
      font-size: 20px;
      font-family: $Prompt;
      font-weight: 400;
      height: 90px;
      margin-bottom: -1px;
      display: inline-block;
      margin-right: 40px;
      background: transparent;
      width: 70%;
      border: none;
      &::placeholder {
        color: $textColor;
        font-size: 30px;
        font-family: $Prompt;
        font-weight: 400;
      }
    }
  }
  .footer_right {
    width: 60%;
  }
  .icons {
    cursor: pointer;

    &:hover {
      color: $textColor !important;
    }
  }
  .btn_main {
    button {
      padding: 4px 40px;
      background-color: transparent;
      border: none;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.8px;
      font-family: $Prompt;
      color: gray;
      font-weight: 300;
      margin: 0 4px;
      box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5);

      &:hover {
        background-color: gray;
        color: #464646;
      }
    }
  }
  ul {
    p {
      font-size: 14px;
      letter-spacing: 1.5px;
      font-weight: 500;
      cursor: pointer;
    }
    li {
      list-style: none;
      font-weight: 400;
      font-size: 13px;
      color: rgb(180, 180, 180);
      margin-bottom: 6px;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
}
// Footer Section End

// Responsive
@media screen and (max-width: 768px) {
  .navbar {
    background-color: #222222 !important;
    .navbar-brand {
      width: 30% !important;
    }
    .search_bar {
      width: 60% !important;
      input {
        width: 100% !important;
      }
    }
  }

  .hero_sec {
    height: calc(100% - 370px);
    padding: 130px 35px 149px !important;
    h3 {
      font-size: 40px !important;
      line-height: 40px !important;
    }
    .right_img {
      display: flex;
      justify-content: center;
      opacity: 0.3;
      mix-blend-mode: exclusion !important;
      bottom: 0% !important;
      height: 30rem !important;
      margin-top: 45rem;
      img {
        width: 80% !important;
      }
    }
    .repeat_txt {
      line-height: 0.5rem !important;
      height: 25vh;
      span {
        font-size: 38px !important;
        line-height: 30px !important;
      }
    }
    p {
      font-size: 16px !important;
      margin-top: 0px !important;
    }
    .btn_main {
      .btn_1 {
        margin: 5px 0 !important;
        font-size: 12px !important;
      }
      .btn_2 {
        margin: 5px 0 !important;
        font-size: 12px !important;
        padding: 4px 20px;
      }
    }
    .small_txt {
      font-size: 10px !important;
      font-weight: 400;
      margin-top: 20px !important;
    }
  }

  .numbers_sec {
    // margin-top: 5rem !important;

    .main_card {
      justify-content: center !important;
      flex-wrap: wrap !important;
      .card_inner {
        margin: 10px 10px !important;
      }
      h4 {
        font-size: 25px !important;
      }
      .para {
        p {
          font-size: 11px !important;
        }
      }
    }
  }

  .section_bg {
    h3 {
      font-size: 36px !important;
      font-weight: 500 !important;
      margin-bottom: 4rem !important;
    }
    h5 {
      font-size: 25px !important;
    }
    .images {
      img {
        width: 30.2% !important;
      }
    }
  }
  .hedera_sec {
    .container {
      padding: 40px 0px !important;
      .img2 {
        // height: 0% !important;
      }
      .tab-res {
        gap: 1rem !important;
      }
      .img3 {
        width: 150px !important;
        height: 200px !important;
      }
      .img-3 {
        display: none !important;
      }
      .text {
        padding: 0 20px;
        position: static !important;
        width: 100% !important;
      }
      .btn_main {
        .btn_1 {
          margin: 5px 0 !important;
        }
        .btn_2 {
          margin: 5px 0 !important;
        }
      }
    }
  }

  .cards_sec {
    h3 {
      font-size: 25px !important;
    }
  }
  .future_sec {
    .clip-text {
      display: flex !important;
      justify-content: center !important;
    }
    h4 {
      font-size: 25px !important;
    }
    .cards_sec {
      margin: 0% !important;
      .btn_main2 {
        button {
          font-size: 13px !important;
          margin: 5px 0;
        }
      }
      .card {
        width: 290px !important;
        .btn2_main {
          display: flex !important;
          justify-content: center !important;
          button {
            font-size: 13px !important;
            margin: 5px 0;
          }
        }
      }
    }
    .react-multiple-carousel__arrow {
      background: linear-gradient(160deg, #21a056, #7e91ff) !important;
      padding: 5px 10px;
      border-radius: 100px !important;
    }
  }
  .started_sec {
    h4 {
      font-size: 30px !important;
    }
  }
  .build_sec {
    h3 {
      font-size: 28px !important;
    }
    p {
      font-size: 14px !important;
    }
    .btn_main {
      .btn_1 {
        margin: 5px 0 !important;
      }
      .btn_2 {
        margin: 5px 0 !important;
      }
    }
  }
  .commitment_sec {
    // margin-top: 30px !important;

    .main_card {
      border: none !important;
      // border-radius: 0% !important;
      background-color: none !important;
      box-shadow: none !important;
      h4 {
        font-size: 30px !important;
      }
      p {
        font-size: 14px !important;
      }
      .btn_main {
        .btn_1 {
          margin: 5px 0 !important;
          font-size: 11px !important;
          padding: 4px 20px !important;
        }
      }
      .sustainability-graph-image {
        transform-origin: top left;
        transform: rotate(-90deg) translate(-89%, 0%);
        height: 100%;
        width: 189%;
        margin-top: 10%;
      }
    }
  }

  .contact_sec {
    h3 {
      font-size: 4rem !important;
    }
    .card {
      h4 {
        font-size: 20px;
      }
      a {
        font-size: 14px;
      }
    }
  }

  .footer {
    margin: 3rem 0 0 0 !important;
    .input_main {
      width: 100% !important;
      input {
        width: 90% !important;
        font-size: 25px !important;
      }
    }
    .footer_right {
      flex-wrap: wrap !important;
      justify-content: center !important;
      width: 100% !important;
      .btn_main {
        button {
          margin: 15px 0 !important;
        }
      }
      .icons {
        margin: 10px 0 !important;
      }
    }
  }
}
.teamGrid {
  display: grid;
  grid-template-columns: 32.33% 32.33% 32.33%;
  column-gap: 1.5%;
  margin-top: 100px;
}
.avatar {
  position: absolute;
  left: 0;
  right: 0;
  top: -80px;
  text-align: center;
}
.teamcolinner {
  position: relative;
}
.member-name h2 {
  font-size: 26px;
  color: #000;
  font-family: $Prompt;
}
.member-info p {
  color: #000;
  font-size: 15px;
  font-family: $Prompt;
}
.avatar > img {
  width: 150px;
  margin: auto;
  border-radius: 50%;

  border: 1px solid rgb(170 170 173/ 1);
  box-shadow: 0px 3px 10px 3px rgb(170 170 173 / 0.5);
}
.teamcolinner {
  position: relative;
  border: 1px dashed #ddd;
  min-height: 100px;
  background: #fff;
  z-index: 9;
}
.teamcol {
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  transition: transform 1s ease-in-out;
}
.teamcol:hover {
  transform: translateY(-30px);
  box-shadow: 0px 3px 10px 3px rgb(170 170 173 / 0.5);
  transition: transform 1s ease-in-out;
}
.teamcol:before {
  content: "";
  width: 50%;
  height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(160deg, #3ec878, #21a056);
  border-top-right-radius: 10px;
  transition: width 1s ease-in-out;
}
.teamcol:after {
  content: "";
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(160deg, #5281e7, #3ec878);
  border-bottom-left-radius: 10px;
  transition: width 1s ease-in-out;
}
.teamcol:hover::before,
.teamcol:hover::after {
  width: 100%;
  transition: width 1s ease-in-out;
}
.member-name {
  margin-top: 80px;
}
.member-mail a {
  color: #565656;
}
.member-info {
  padding: 0px 20px;
}
.social-listing {
  align-items: center;
  justify-content: center;
  display: flex;
  list-style: none;
  padding: 0;
}
.social-listing > li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #f4f5f7;
  border-radius: 50%;
  margin: 5px;
}
.social-listing > a {
  color: #565656 !important;
}
